import React, { useContext, useEffect, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import Loading from "@/components/Loading";
import Api from "@/api";
import {
  FullDescriptionView,
  FullUserView,
  OwnerDescriptionView,
  ShortDescriptionView,
} from "@/api/generated";
import {
  DescriptionDispatchContext,
  DescriptionStateContext,
} from "@/contexts/DescriptionContext";
import { useAuth } from "@/hooks/useAuth";
import useStorage from "@/hooks/useStorage";
import { KEY_LAST_VISIT_USER } from "@/lib/storageKeys";
import { useAuthHeaders } from "@/lib/authHeader";
import GridProfileLayout from "@/components/Layout/GridProfileLayout";
import GridLayoutSection from "@/components/GridItems/GridLayoutSection";
import Descriptions from "@/components/descriptions";

function Username() {
  const { authHeader } = useAuthHeaders();
  const router = useRouter();
  const { username } = router.query;
  const { user: authUser } = useAuth({
    middleware: "guest",
  });
  const [user, setUser] = useState<FullUserView | null>(null);
  const [descriptions, setDescriptions] =
    useState<
      (FullDescriptionView | ShortDescriptionView | OwnerDescriptionView)[]
    >();
  const [isChildLoaded, setIsChildLoaded] = useState(false);
  const [hasMutualDescriptions, setHasMutualDescriptions] = useState(false);
  const { setSessionItem } = useStorage();

  const state = useContext(DescriptionStateContext);
  const dispatch = useContext(DescriptionDispatchContext);

  useEffect(() => {
    dispatch({
      type: "CLOSE_PREVIEW",
    });
    if (!username) {
      return;
    }
    if (username !== user?.username) {
      setUser(null);
    }

    Api.User.getApiUsersShow(username as string)
      .then((res) => {
        setUser(res.data);
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          router.push("/404");
          return;
        }
      });

    return () => {
      setUser(null);
      dispatch({
        type: "INIT_DESCRIPTIONS",
        payload: {
          descriptions: [],
        },
      });
    };
  }, [username]);

  useEffect(() => {
    if (!user || !authHeader) {
      return;
    }

    setSessionItem(KEY_LAST_VISIT_USER, user.username || "");

    if (authUser) {
      Api.MyDescriptions.getApiDescriptionsMeListByUsername(
        user.username as string,
        0,
        50,
        "updatedAt|desc",
        authHeader
      ).then((res) => {
        dispatch({
          type: "INIT_DESCRIPTIONS",
          payload: {
            descriptions: res.data.data || [],
          },
        });
      });

      Api.MyDescriptions.getApiDescriptionsMeMutualListByUsername(
        user.username as string,
        authHeader
      ).then((res) => {
        setHasMutualDescriptions((res.data.data || []).length > 0);
      });

      return;
    }

    Api.Descriptions.getApiDescriptionsListByUsername(
      user.username as string,
      0,
      50,
      "updatedAt|desc"
    ).then((res) => {
      dispatch({
        type: "INIT_DESCRIPTIONS",
        payload: {
          descriptions: res.data.data || [],
        },
      });
    });
  }, [user, authUser]);

  useEffect(() => {
    setDescriptions(
      state.descriptions.filter((descriptions) =>
        state.userDescriptionIds.includes(descriptions?.id || "")
      )
    );
  }, [state.userDescriptionIds, state.descriptions]);

  if (!user) return <Loading />;

  const pageTitle = `${user.full_name} | Dscribe.me`;
  const pageDescription = `Explore ${user.full_name}'s personal narrative on Dscribe.me, a platform that reinvents personal introductions through trusted communal insights.`;
  const pageUrl = `https://dscribe.me/${user.username}`;

  return (
    <>
      {!isChildLoaded && <Loading />}
      <GridProfileLayout authUser={authUser} user={user}>
        <Head>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:url" content={pageUrl} />
          <meta name="twitter:url" content={pageUrl} />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Head>

        <GridLayoutSection
          user={user}
          onChildLoaded={() => setIsChildLoaded(true)}
        />

        <Descriptions
          authUser={authUser}
          descriptions={descriptions}
          hasMutualDescriptions={hasMutualDescriptions}
        />
      </GridProfileLayout>
    </>
  );
}

export default Username;
